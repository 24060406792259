const A_CHAR_CODE = 65
const M_CHAR_CODE = 77

export const optionTranslator: (symbol: string, onlyWeekMode?: boolean) => string = (
  symbol: string,
  onlyWeekMode?: boolean,
) => {
  if (!symbol || symbol.length != 10) {
    return ''
  }
  const weekCode = symbol[2]
  const readableSymbol = getStrikePrice(symbol)
  const isCall = isCallOption(symbol)
  const month = charToMonth(symbol[8])
  const cpType = isCall ? 'Call' : 'Put'
  const isMonthExipre = weekCode === 'O'

  const result = isMonthExipre
    ? `台指${month}月${onlyWeekMode ? 'W3' : '月'}選${readableSymbol}${cpType}`
    : `台指${month}月W${weekCode}選${readableSymbol}${cpType}`
  return result
}

export const opbsAdvancedTranslator: (symbol: string) => string = (symbol: string) => {
  if (symbol.length === 10) return optionTranslator(symbol)

  const weekCode = symbol[2]
  const month = charToMonth(symbol[8])
  const isMonthExipre = weekCode === 'O'
  const preResult = `台指${month}月${isMonthExipre ? '月' : `W${weekCode}`}選-`
  if (symbol.includes(':')) {
    // 跨式 ---> TX218850A2:M2
    if (symbol.length == 13) {
      const price = symbol.substring(3, 8)
      return `${preResult}跨式${price}`
    }
    // 勒式 ---> TX218750A2:18900M2
    if (symbol.length == 18) {
      const price1 = symbol.substring(3, 8) + getType(symbol[8])
      const price2 = symbol.substring(11, 16) + getType(symbol[16])
      return `${preResult}勒式${price1}/${price2}`
    }
    return '錯誤格式'
  } else if (symbol.includes('-')) {
    // 轉換 逆轉 ---> TX218350A2-M2
    if (symbol.length == 13) {
      const price = symbol.substring(3, 8)
      return `${preResult}轉${price}`
    }
    return '錯誤格式'
  } else if (symbol.includes('/')) {
    // 價差 ---> TX219200/18700A2
    if (symbol.length == 16) {
      const price1 = symbol.substring(3, 8)
      const price2 = symbol.substring(9, 14)
      const localMonth = charToMonth(symbol[symbol.length - 2])
      const localPreResult = `台指${localMonth}月${isMonthExipre ? '月' : `W${weekCode}`}選-`
      const typeChinese = getType(symbol[symbol.length - 2]) === 'C' ? '買權' : '賣權'
      return `${localPreResult}${typeChinese}價差${price1}/${price2}`
    }
    return '錯誤格式'
  } else return '錯誤格式'
}

/**
 * @example
 *   ;(char = K), (isCall = true)
 *   return 11
 *
 * @param char
 * @param isCall
 * @returns Month
 */
export const charToMonth = (char: string) => {
  const isCall = char <= 'L'
  return (isCall ? char.charCodeAt(0) - A_CHAR_CODE : char.charCodeAt(0) - M_CHAR_CODE) + 1
}

export const isOpbsSymbol = (symbol: string) => {
  return symbol.length >= 10 && symbol.substring(0, 2) === 'TX'
}

export const isPureOpbsSymbol = (symbol: string) => {
  return symbol.length === 10 && symbol.substring(0, 2) === 'TX'
}

/** 分辨`買權`或`賣權`，非選擇權商品狀況不考慮 */
export const isCallOption = (opbsSymbol: string) => {
  return opbsSymbol.charAt(8) <= 'L'
}

/** @deprecated 選擇權取出履約價的部分 */
export const getStrikePrice = (opbsSymbol: string | undefined) => {
  if (!opbsSymbol) return ''
  return opbsSymbol.substring(3, 8)
}

/** 選擇權取出履約價以及賣買權的字母 */
export const getStrikePriceAndType = (opbsSymbol: string | undefined) => {
  if (!opbsSymbol) return ''
  return opbsSymbol.substring(3, 8) + (isCallOption(opbsSymbol) ? 'C' : 'P')
}

export const getType = (char: string) => {
  return char <= 'L' ? 'C' : 'P'
}
