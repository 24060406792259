export enum FrInstrumentType {
  台灣股票 = 'stock',
  台灣選擇權 = 'option',
  指數 = 'index',
  期貨 = 'futures',
  未知 = 'unknown',
  海外股票 = 'os_stock',
}

export enum FrInstrumentExchange {
  TWSE = 'TWSE',
  TFE = 'TFE',
  binance = 'binance',
  URX = 'URX',
  CBT = 'CBT',
  CME = 'CME',
  SGX = 'SGX',
  HKF = 'HKF',
  IUS = 'IUS',
  NYM = 'NYM',
  CMX = 'CMX',
  NASDAQ = 'NASDAQ',
  NYSE = 'NYSE',
  DJ = 'DJ',
  RUSSEL = 'RUSSEL',
  SP = 'SP',
  CBOE = 'CBOE',
}

export declare namespace FrInstrumentTypes {}
