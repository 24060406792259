import {
  twAMFutures,
  twPMFutures,
  twAutoSessionFutures,
  cmeFutures,
  cbotFutures,
  cbotC,
  nybotKC,
  nybotSB,
  nybotCC,
  sgxTWN,
  sgxCN,
  sgxNK,
  sgxIN,
  hkfFutures,
  hkfMTW,
  eurex,
  usStock,
  foreign,
} from '~/modules/monitor/symbolGroups'
import dayAPI from '~/utils/dayAPI'
import {
  twStockTimeRange,
  twAMFuturesTimeRange,
  twPMFuturesTimeRange,
  twAutoSessionTimeRange,
  cmeFuturesTimeRange,
  sgxCNTimeRange,
  usStockTimeRange,
  allDayTimeRange,
  sgxTWNTimeRange,
  sgxINTimeRange,
  sgxNKTimeRange,
  hkfMTWTimeRange,
  hkfFuturesTimeRange,
  cbotFuturesTimeRange,
  cbotCTimeRange,
  nybotSBTimeRange,
  nybotCCTimeRange,
  nybotKCTimeRange,
  eurexTimeRange,
  foreignTimeRange,
} from './specTimeRangeUtil'

export const getSymbolFromTo = (symbol: string, date: any) => {
  if (symbol.match(/^\d{4,5}[A-Z]?$/)) return twStockTimeRange(date)
  if (symbol.match(/^[A-Z]{2}F-\d+$/)) return twAMFuturesTimeRange(date) // 股期
  if (symbol.match(/^TX[12O45]\d{5}[A-X]\d$/)) return twAutoSessionTimeRange(date) // 選擇權
  if (symbol.match(/^(TSE\w*|OTCA|TWXI)$/)) return twStockTimeRange(date) // 國內指數

  const splitPos = symbol.indexOf('-')
  const baseSymbol = splitPos == -1 ? symbol : symbol.substring(0, splitPos)
  if (twAutoSessionFutures.indexOf(baseSymbol) != -1) return twAutoSessionTimeRange(date)
  if (twAMFutures.indexOf(baseSymbol) != -1) return twAMFuturesTimeRange(date)
  if (twPMFutures.indexOf(baseSymbol) != -1) return twPMFuturesTimeRange(date)

  if (nybotKC.indexOf(baseSymbol) != -1) return nybotKCTimeRange(date)
  if (nybotSB.indexOf(baseSymbol) != -1) return nybotSBTimeRange(date)
  if (nybotCC.indexOf(baseSymbol) != -1) return nybotCCTimeRange(date)
  if (sgxTWN.indexOf(baseSymbol) != -1) return sgxTWNTimeRange(date)
  if (sgxCN.indexOf(baseSymbol) != -1) return sgxCNTimeRange(date)
  if (sgxNK.indexOf(baseSymbol) != -1) return sgxNKTimeRange(date)
  if (sgxIN.indexOf(baseSymbol) != -1) return sgxINTimeRange(date)
  if (hkfFutures.indexOf(baseSymbol) != -1) return hkfFuturesTimeRange(date)
  if (hkfMTW.indexOf(baseSymbol) != -1) return hkfMTWTimeRange(date)
  if (cmeFutures.indexOf(baseSymbol) != -1) return cmeFuturesTimeRange(date)
  if (cbotFutures.indexOf(baseSymbol) != -1) return cbotFuturesTimeRange(date)
  if (cbotC.indexOf(baseSymbol) != -1) return cbotCTimeRange(date)
  if (eurex.indexOf(baseSymbol) != -1) return eurexTimeRange(date)
  if (usStock.indexOf(baseSymbol) != -1) return usStockTimeRange(date)
  if (foreign.indexOf(baseSymbol) != -1) return foreignTimeRange(date)

  return allDayTimeRange(dayAPI())
}
