import { isString } from 'lodash'

/** 幫把傳入的 arguments 改大寫 */
export function ArgUpperCase<TargetType>(options?: {
  /** 第1個 arg 要改嗎？預設 `要改` */
  arg1?: true
  /** 第2個 arg 要改嗎？預設 `不改` */
  arg2?: boolean
  /** 第3個 arg 要改嗎？預設 `不改` */
  arg3?: boolean
  /** 第n個 arg 要改嗎？預設 `不改` */
  arg4?: boolean
}) {
  return function methodDecorator(
    instance: TargetType,
    methodName: string,
    descriptor: PropertyDescriptor,
  ) {
    const fn = descriptor.value
    descriptor.value = function (...args: any[]) {
      if (isString(args[0])) {
        args[0] = args[0].toUpperCase()
      }
      if (isString(args[1]) && options?.arg2) {
        args[1] = args[1].toUpperCase()
      }
      if (isString(args[2]) && options?.arg3) {
        args[2] = args[2].toUpperCase()
      }
      if (isString(args[3]) && options?.arg4) {
        args[3] = args[3].toUpperCase()
      }
      return fn.apply(this, args)
    }
  }
}
